import React, { useEffect } from 'react';
import firebase from "firebase/app";
import "firebase/auth";
import './App.css';

// Initialize Firebase
const config = {
  apiKey: "AIzaSyCOatIvc4WvoTr11LLp0h_yJPONwsjpGS4",
  authDomain: "znote-6233a.firebaseapp.com",
  databaseURL: "https://znote-6233a.firebaseio.com",
  projectId: "znote-6233a",
  storageBucket: "znote-6233a.appspot.com",
  messagingSenderId: "374762474786",
  appId: "1:374762474786:web:e60a4345ae9ca2c732697b",
  measurementId: "G-S53DT6NC7N"
};
firebase.initializeApp(config);

function App() {

  const [message, setMessage] = React.useState("Authenticating... Allow popup if not displayed");

  useEffect(() => {
    init();
  }, []);

  const init = async () => {

    // default Google
    let provider = new firebase.auth.GoogleAuthProvider();

    const params = new URLSearchParams(window.location.search)
    if (params.get("ot-provider")) {
      if (params.get("ot-provider") === "Apple") {
        provider = new firebase.auth.OAuthProvider('apple.com');
      }
    }

    const auth = firebase.auth();

    auth.signInWithPopup(provider).then(async (result) => {
      //const credential = GoogleAuthProvider.credentialFromResult(result);
      //const token = credential.accessToken;
      //const user = result.user;

      console.log("Grabbed the user", result.user);

      const token = await result.user.getIdToken()
      const code = params.get("ot-auth-code")

      const response = await fetch(`/create-auth-token?ot-auth-code=${code}&id-token=${token}`)
      if (response.status === 200) {
        setMessage("You are now authenticated. Go back to your Znote application.")
      }

    }).catch((error) => {
      console.log("Unable to retrieve user", error.code, error.message);
    });
  }

  return (
      <span>{message}</span>
  );
}

export default App;
